import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";
import LazyBackground from "theme/components/atoms/LazyBackground/LazyBackground";

const WidgetCardLink = ( {link, children} ) => {
    return link ?
      <Link appearance="default" to={link}>
          { children }
      </Link>
      : <>{ children }</>
}

const WidgetCard = ({ attributes }) => {
    const widgetclass = (typeof attributes.widgetclass  !== 'undefined') ? attributes.widgetclass : "";

    const containerDivClass = 'fcwidget fcw-card ' + widgetclass;

    const textVert = 'fcw-vert-align-bottom';
    const textPosition = 'fcw-hor-pos-left';
    const textHorAlign = 'fcw-hor-align-left';

    const contentClasses = 'content ' + textVert + ' ' + textPosition + ' ' + textHorAlign;
    const contentClassesMobile = contentClasses;

    const fullContainerClass = 'fcw-card-container';

    const buttonClass = attributes.textcolor === "#FFFFFF" ? "primary-reverse" : "primary";
    const buttonClassMobile = attributes.textcolor_mobile === "#FFFFFF" ? "primary-reverse" : "primary";

    const bgDesktopImageSrc = '/media/' + attributes.image;
    const bgMobileImageSrc = '/media/' + attributes.image_mobile;


    const titleColor = attributes.textcolor === "" ? "#FFFFFF" : attributes.textcolor;
    const titleColorMobile = attributes.textcolor_mobile === "" ? "#FFFFFF" : attributes.textcolor_mobile;

    const fcwtitlestyle = {
        color: titleColor,
    };

    const fcwtitlestyleMobile = {
        color: titleColorMobile,
    };

    const fcwFullContainerStyle = {
        minHeight: attributes.minheight + 'px',
        width: attributes.widtpercent + '%',
    };

    const fcwFullContainerStyleMobile = {
        minHeight: attributes.minheight_mobile + 'px',
        width: '100%',
        float: 'none',
    };

    return(
      <>
          <MediaQuery minWidth={Breakpoints.mobile}>
              <div className={containerDivClass} style={fcwFullContainerStyle}>
                  <div className={fullContainerClass}>
                      <WidgetCardLink link={attributes.button1_link}>
                          <LazyBackground className="fcw-bgimage" src={bgDesktopImageSrc} />
                      </WidgetCardLink>
                      <div className={contentClasses}>
                          <WidgetCardLink link={attributes.button1_link}>
                              <div className="h4" style={fcwtitlestyle}>{attributes.title}</div>
                          </WidgetCardLink>
                          {attributes.button1_text?.length > 0 &&
                          <Link 
                          buttonAppearance={buttonClass} to={attributes.button1_link} modifiers={{ "button-no-border": true }}>{attributes.button1_text}</Link>
                          }
                          {attributes.button2_text?.length > 0 &&
                          <Link buttonAppearance={buttonClass} to={attributes.button2_link} modifiers={{ "button-no-border": true }}>{attributes.button2_text}</Link>
                          }
                      </div>
                  </div>
              </div>
          </MediaQuery>
          <MediaQuery maxWidth={Breakpoints.mobile - 1}>
              <div className={containerDivClass} style={fcwFullContainerStyleMobile}>
                  <div className={fullContainerClass} >
                      <WidgetCardLink link={attributes.button1_link}>
                          <LazyBackground className="fcw-bgimage" src={bgMobileImageSrc} />
                      </WidgetCardLink>
                      <div className={contentClassesMobile} >
                          <WidgetCardLink link={attributes.button1_link}>
                              <div className="h4" style={fcwtitlestyleMobile}>{attributes.title}</div>
                          </WidgetCardLink>
                          {attributes.button1_text?.length > 0 &&
                          <Link buttonAppearance={buttonClassMobile} to={attributes.button1_link} modifiers={{ "button-no-border": true }}>{attributes.button1_text}</Link>
                          }
                          {attributes.button2_text?.length > 0 &&
                          <Link buttonAppearance={buttonClassMobile} to={attributes.button2_link} modifiers={{ "button-no-border": true }}>{attributes.button2_text}</Link>
                          }
                      </div>
                  </div>
              </div>
          </MediaQuery>
      </>
    );

}

export default WidgetCard;
